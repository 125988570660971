export default {
  global: {
    colors: {
      brand: "#00B7D0",
      "light-6": "#B4B4B4",
      "accent-1": "#B4B4B4",
    },
    font: {
      family: "Poppins",
      size: "14px",
      height: "20px",
    },
  },
  layer: {
    zIndex: 999,
    container:{
      zIndex: '998'
    }
  }
};
