import { Grommet } from "grommet";
import React from "react";
import { Helmet } from "react-helmet";
import { FontContainer } from "../../config/typography";
import GlobalDiv from "./global.less";
import themeVariables from "./theme";
import { withPrefix, Link } from "gatsby"

export default function SiteLayout(props) {
  return (
    <>
      <Grommet theme={themeVariables} full>
        <>
          <Helmet title="OneLink" >
            <link href='https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap' rel='stylesheet' />

          </Helmet>
          <GlobalDiv />
          <FontContainer />
          <main>{props.children}</main>
        </>
      </Grommet>
    </>
  );
}
