import styled from "styled-components";

let GlobalDiv = styled.div`
  .layout {
    main.container {
      width: 100%;
      height: 80px;
      display: flex;
      align-self: center;
      margin: auto;
    }
  }
  main {
    height: 100vh;
    width: 100vw;
  }
  body {
    background-color: red !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  h1 {
    font-size: 70pt !important;
  }

  h2 {
    font-size: 48pt !important;
  }

  h3 {
    font-size: 32pt !important;
  }

  h4 {
    font-size: 24pt !important;
  }

  h5 {
    font-size: 22pt !important;
  }

  h6 {
    font-size: 18pt !important;
  }

  p {
    font-size: 18pt !important;
    letter-spacing: normal;

    &.small {
      font-size: 18pt !important;
    }

    &.large {
      font-size: 24pt !important;
    }

    &.xlarge {
      font-size: 30pt !important;
    }
  }

  img {
    padding: 0px 0px;
    margin: 0px 0px;
  }
  .brandLink {
    display: block;
    width: 160px;
    height: 48px;
    border-radius: 4px;
    color: white;
    line-height: 48px;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-family: "Poppins";
    font-weight: 400;
    text-align: center;
  }

  .centerInside {
    display: flex;
    justify-content: center;
    display: block;
    text-align: center;
    * {
      margin: 0px auto;
    }
  }

  .pad16 {
    padding: 16px;
  }
  .pad24 {
    padding: 24px;
  }
  .pad32 {
    padding: 32px;
  }

  ul {
    margin: 0px;
    padding: 0px;
    li {
      margin: 0px;
      padding: 0px;
    }
  }

  nav#top-nav {
    padding: 25px 0px;
    .brandLogo {
      width: 82px;
      height: 60px;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 45px !important;
    }

    h2 {
      font-size: 34px !important;
    }

    h3 {
      font-size: 28px !important;
    }

    h4 {
      font-size: 24px !important;
    }

    h5 {
      font-size: 20px !important;
    }

    h6 {
      font-size: 18px !important;
    }
  }
`;
export default GlobalDiv;
